import React from 'react';
import Header from './Header';
import Footer from './Footer'; // Importing the Footer component
import '../scss/layout.scss'; // Importing the layout styles

function Layout({ children }) {
  return (
    <div className="layout-container">
      <Header /> {/* Header */}

      {/* Main Content */}
      <main className="main-content container mt-4">{children}</main>

      {/* Footer */}
      <Footer /> {/* Using the Footer component */}
    </div>
  );
}

export default Layout;
