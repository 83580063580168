// src/Pages/homePage.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import '../scss/homePage.scss'; 

function Home() {
  return (
    <div className="home-page container d-flex align-items-center justify-content-center min-vh-100">
      <div className="text-center">
        {/* Hero Section */}
        <h1 className="text-primary display-4 mb-4">Welcome to MyWebsite</h1>
        <p className="lead mb-4">
          Discover my projects, insights, and resources that showcase my skills and experience. Let's make an impact together.
        </p>
        <Link to="/projects" className="btn btn-custom btn-lg">Explore My Work</Link>
      </div>
    </div>
  );
}

export default Home;
