//src/Pages/projectPage.jsx
import React from 'react';
import { Link } from 'react-router-dom';

function ProjectPage() {
  return (
    <div className="project-page">
      {/* Page Title */}
      <h1 className="mb-4">Projects</h1>

      {/* Project List Section */}
      <div className="row">
        {/* Project 1: Painless Analytics */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Painless Analytics</h5>
              <hr style={{ borderColor: 'gray', margin: '0.5rem 0' }} />
              <p className="card-text">
                Launched a new subscription-based service, enabling seamless online ordering, integrating Stripe for secure payments, and automating customer management, leading to increased user engagement and revenue growth.
              </p>
            </div>
            <div className="card-footer">
              <a 
                href="https://www.painlessanalytics.com" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn btn-primary"
              >
                Link to Website
              </a>
            </div>
          </div>
        </div>

        {/* Project 2: Hevy Page Workout Tracker */}
        <div className="col-md-4 mb-4">
          <div className="card h-100">
            <div className="card-body">
              <h5 className="card-title">Hevy Page Workout Tracker</h5>
              <hr style={{ borderColor: 'gray', margin: '0.5rem 0' }} />
              <p className="card-text">
                A workout tracking application that displays detailed summaries of user workout sessions. Key features include dynamic workout navigation, interactive graphs for visualizing weightlifting progress, and the ability to view historical workout data. Built with React, Bootstrap, and custom data fetching and processing scripts for seamless user experience.
              </p>
              <small className="text-warning">⚠️ Work in Progress</small>
            </div>
            <div className="card-footer">
              <Link to="/hevy" className="btn btn-primary">Learn More</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
