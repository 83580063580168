//src/Components/Footer.jsx

import React from 'react';
import '../scss/footer.scss'; 

function Footer() {
  return (
    <footer className="footer">
      <p className="footer-text">&copy; 2024 Prakash-Somani.com. All Rights Reserved.</p>
      <div className="footer-links">
        <a href="/about" className="footer-link">About</a>
        <a href="/contact" className="footer-link">Contact</a>
        <a 
          href="/api/changelog" 
          className="footer-link" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          Roadmap & Changelog
        </a>
      </div>
    </footer>
  );
}

export default Footer;
