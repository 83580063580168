// src/Pages/HevyPage.jsx
import React, { useEffect, useState } from 'react';
import { fetchAllWorkouts } from '../scripts/fetchWorkouts'; 
import { convertWorkoutsToLbs } from '../scripts/processWorkouts';
import GraphComponent from '../Components/GraphComponent'; 
import { Card, Table, Form, FormControl, Spinner, Button } from 'react-bootstrap'; 

function HevyPage() {
  const [workouts, setWorkouts] = useState([]);
  const [mostRecentWorkout, setMostRecentWorkout] = useState(null);
  const [selectedExerciseData, setSelectedExerciseData] = useState([]);
  const [exerciseList, setExerciseList] = useState([]);
  const [selectedExercise, setSelectedExercise] = useState('');
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false); // State to control expansion
  const [currentWorkoutIndex, setCurrentWorkoutIndex] = useState(0);

  useEffect(() => {
    async function getAllWorkouts() {
      let data = await fetchAllWorkouts();
      if (data && data.length > 0) {
        data = convertWorkoutsToLbs(data); // Convert weights to lbs
        setWorkouts(data);
        setCurrentWorkoutIndex(0); // Start with the most recent workout
        const exercises = new Set();
        data.forEach(workout => {
          workout.exercises.forEach(exercise => exercises.add(exercise.title));
        });
        setExerciseList([...exercises]);
      }
      setLoading(false);
    }
  
    getAllWorkouts();
  }, []);
  

  useEffect(() => {
    if (workouts.length > 0 && selectedExercise) {
      const aggregatedData = workouts
        .map(workout => {
          const matchingExercise = workout.exercises.find(ex => ex.title === selectedExercise);
          if (matchingExercise && matchingExercise.sets.length > 0) {
            return {
              date: new Date(workout.start_time),
              formattedDate: new Date(workout.start_time).toLocaleDateString(),
              weight: matchingExercise.sets[0].weight_kg || 0,
            };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => a.date - b.date);

      setSelectedExerciseData(aggregatedData);
    }
  }, [workouts, selectedExercise]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const goToPreviousWorkout = () => {
    if (currentWorkoutIndex < workouts.length - 1) {
      setCurrentWorkoutIndex(currentWorkoutIndex + 1);
    }
  };
  
  const goToNextWorkout = () => {
    if (currentWorkoutIndex > 0) {
      setCurrentWorkoutIndex(currentWorkoutIndex - 1);
    }
  };
  

  const renderSummaryCard = () => {
    const currentWorkout = workouts[currentWorkoutIndex];
    if (!currentWorkout || !currentWorkout.exercises || currentWorkout.exercises.length === 0) {
      return <p>No recent workouts available.</p>;
    }
  
    const totalExercises = currentWorkout.exercises.length;
    const workoutDate = new Date(currentWorkout.start_time).toLocaleDateString();
    const totalSets = currentWorkout.exercises.reduce((acc, ex) => acc + ex.sets.length, 0);
    const totalWeightLifted = currentWorkout.exercises.reduce(
      (acc, ex) => acc + ex.sets.reduce((setAcc, set) => setAcc + (parseFloat(set.weight_lbs) || 0) * (set.reps || 1), 0),
      0
    );
  
    const graphData = currentWorkout.exercises.map(exercise => {
      const firstSet = exercise.sets.length > 0 ? exercise.sets[0] : { weight_lbs: 0, reps: 0 };
      return {
        name: exercise.title,
        weight: parseFloat(firstSet.weight_lbs) || 0,
        reps: firstSet.reps || 0,
      };
    });
  
    return (
      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Button variant="link" onClick={goToPreviousWorkout} disabled={currentWorkoutIndex >= workouts.length - 1}>
              ← Previous Workout
            </Button>
            <Card.Title>Workout Summary</Card.Title>
            <Button variant="link" onClick={goToNextWorkout} disabled={currentWorkoutIndex <= 0}>
              Next Workout →
            </Button>
          </div>
          <Card.Text>
            <strong>Date:</strong> {workoutDate} <br />
            <strong>Total Exercises:</strong> {totalExercises} <br />
            <strong>Total Sets:</strong> {totalSets} <br />
            <strong>Total Weight Lifted:</strong> {totalWeightLifted.toFixed(2)} lbs
          </Card.Text>
          <GraphComponent data={graphData.map(entry => ({ date: entry.name, weight: entry.weight, reps: entry.reps, name: entry.name }))} showCustomTooltip={true} />
        </Card.Body>
        <Card.Footer className="text-center">
          <Button variant="link" onClick={() => setExpanded(!expanded)}>
            {expanded ? 'Collapse' : 'Expand'} Details
          </Button>
        </Card.Footer>
      </Card>
    );
  };
  


  return (
    <div className="hevy-page">
      {/* Render the summary card */}
      {renderSummaryCard()}

      {/* Conditionally render the rest of the content based on expanded state */}
      {expanded && (
        <>
          {workouts[currentWorkoutIndex] && workouts[currentWorkoutIndex].exercises && workouts[currentWorkoutIndex].exercises.length > 0 ? (
            workouts[currentWorkoutIndex].exercises.map((exercise, index) => (
              <Card key={index} className="mb-4">
                <Card.Body>
                  <Card.Title as="h2">{exercise.title}</Card.Title>
                  <h4>Sets:</h4>
                  <Table striped bordered hover size="sm">
                    <thead>
                      <tr>
                        <th>Set #</th>
                        <th>Weight (lbs)</th>
                        <th>Reps</th>
                      </tr>
                    </thead>
                    <tbody>
                      {exercise.sets.map((set, setIndex) => (
                        <tr key={setIndex}>
                          <td>{setIndex + 1}</td>
                          <td>{set.weight_lbs ? set.weight_lbs : 'N/A'} lbs</td>
                          <td>{set.reps}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <GraphComponent
                    data={workouts
                      .map(workout => {
                        const matchingExercise = workout.exercises.find(ex => ex.title === exercise.title);
                        if (matchingExercise && matchingExercise.sets.length > 0) {
                          return {
                            date: new Date(workout.start_time).toLocaleDateString(),
                            weight: matchingExercise.sets[0].weight_lbs || 0,
                          };
                        }
                        return null;
                      })
                      .filter(Boolean)
                      .sort((a, b) => new Date(a.date) - new Date(b.date))}
                  />
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No exercises found for the selected workout.</p>
          )}


          <div className="exercise-selection">
            <h2>View Progress for Specific Exercises</h2>
            <Form.Group controlId="exercise-select">
              <Form.Label>Select an Exercise:</Form.Label>
              <FormControl
                as="select"
                value={selectedExercise}
                onChange={(e) => setSelectedExercise(e.target.value)}
              >
                <option value="">--Choose an Exercise--</option>
                {exerciseList.map((exercise, index) => (
                  <option key={index} value={exercise}>
                    {exercise}
                  </option>
                ))}
              </FormControl>
            </Form.Group>

            {selectedExercise && selectedExerciseData.length > 0 ? (
              <div>
                <h3>Progress Over Time for "{selectedExercise}"</h3>
                <GraphComponent data={selectedExerciseData} />
              </div>
            ) : (
              <p>Please select an exercise to view the graph.</p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default HevyPage;
