// src/scripts/fetchWorkouts.js
export async function fetchAllWorkouts() {
  try {
      //const response = await fetch('http://localhost:5000/api/workouts'); 
      const response = await fetch(`/api/workouts`);
      console.log(`Fetching from: ${process.env.REACT_APP_API_BASE_URL}/api/workouts`);
      if (response.ok) {
          const data = await response.json();
          return data;
      } else {
          console.error('Failed to fetch workouts:', response.status, response.statusText);
          return [];
      }
  } catch (error) {
      console.error('Error fetching workouts:', error);
      return [];
  }
}
