//src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/homePage';
import Layout from './Components/Layout';
import About from './Pages/aboutPage';
import Contact from './Pages/contactPage';
import Project from './Pages/projectPage'
import HevyPage from './Pages/hevyPage';
import NotFoundPage from './Pages/NotFoundPage';

import './scss/custom.scss';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/hevy" element={<HevyPage />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route for 404 */}
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
