// src/scripts/processWorkouts.js
export function convertWorkoutsToLbs(workouts) {
    // Conversion factor from kg to lbs
    const kgToLbsFactor = 2.20462;
  
    // Process the workouts data and convert weights from kg to lbs
    return workouts.map(workout => ({
      ...workout,
      exercises: workout.exercises.map(exercise => ({
        ...exercise,
        sets: exercise.sets.map(set => {
          // Convert weight_kg to lbs and store it as weight_lbs
          const weightInKg = set.weight_kg;
  
          // Ensure weightInKg is a valid number
          const weightInLbs = typeof weightInKg === 'number' && !isNaN(weightInKg)
            ? (weightInKg * kgToLbsFactor).toFixed(2) // Convert and format to 2 decimal places
            : null; // Default to null if invalid
  
          return {
            ...set,
            weight_kg: weightInKg, // Retain original kg value if needed
            weight_lbs: weightInLbs // Add new field with converted value
          };
        })
      }))
    }));
  }
  