//src/Pages/contactPage.jsx
import React, { useState } from 'react';

function ContactPage() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState('');
  const [statusType, setStatusType] = useState(''); // 'success' or 'error'
  const [errors, setErrors] = useState({});
  const [showBanner, setShowBanner] = useState(false); // Control visibility of the banner

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setErrors({ ...errors, [id]: '' }); // Clear errors on change
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = 'Name is required';
    }
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }
    if (!formData.message.trim()) {
      validationErrors.message = 'Message is required';
    }
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    try {
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message, // Send raw message
        }),
      });
      const data = await response.json();
  
      setStatus(data.message);
      setStatusType('success');
      setShowBanner(true);
  
      // Clear form data
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      setStatus('Error sending message');
      setStatusType('error');
      setShowBanner(true);
    }
  
    setTimeout(() => {
      setShowBanner(false);
    }, 3000);
  };
  

  return (
    <div className="container mt-5">
      <h1>Contact Me</h1>
      <p className="lead">I'd love to hear from you! You can reach out to me using the information below.</p>

      <section className="mb-4">
        <h2>Contact Information</h2>
        <ul className="list-unstyled">
          <li>
            <strong>Email:</strong> <a href="mailto:prkshsmn@gmail.com">prkshsmn@gmail.com</a>
          </li>
          <li>
            <strong>Phone:</strong> <a href="tel:+18473091509">847-309-1509</a>
          </li>
          <li>
            <strong>Location:</strong> Cincinnati, OH 45219
          </li>
          <li>
            <strong>LinkedIn:</strong> <a href="https://www.linkedin.com/in/prakash-somani-8971a21b0/" target="_blank" rel="noopener noreferrer">Prakash Somani</a>
          </li>
        </ul>
      </section>



      <section className="mb-4">
        <h2>Send Me a Message</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && <div className="text-danger">{errors.name}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="text-danger">{errors.email}</div>}
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea
              className="form-control"
              id="message"
              rows="5"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && <div className="text-danger">{errors.message}</div>}
          </div>

                      {/* Status Banner */}
          {showBanner && (
            <div
              className={`alert ${statusType === 'success' ? 'alert-success' : 'alert-danger'} alert-dismissible fade show`}
              role="alert"
            >
              {status}
              <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowBanner(false)}></button>
            </div>
          )}

          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </section>


    </div>
  );
}

export default ContactPage;
