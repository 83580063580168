//src/Pages/aboutPage.jsx
import React from 'react';
import TimelineItem from '../Components/TimelineItem';

function AboutPage() {
  return (
    <div className="container mt-5">
      <h1>
        <a href="/Prakash_Somani_Resume_2024.pdf" download>
          About Me
        </a>
      </h1>
      <p className="lead">
        Welcome to my professional journey. Explore my background, expertise, and accomplishments as a software engineer dedicated to delivering innovative solutions and driving impactful results.
      </p>

      <section className="mb-4">
        <div className="timeline-container">
          <div className="timeline-line"></div>

          
          <TimelineItem
            year="2018"
            title="Brooksource | Libertyville, IL"
            subtitle="Price Analyst Intern - May 2018 – August 2018"
            description="Implemented process improvements, recommendations, and training for pricing analysis using modern technologies."
            alignLeft={false}
          />

          <TimelineItem
            year="2021"
            title="HydraForce | Libertyville, IL"
            subtitle="Applications Engineer Intern - May 2021 – August 2021"
            description=""
            items={[
              'Developed and documented Automated Wheel-Correction for Steering Mode prototype using HydraForces ECDR ECU.'
            ]}
            alignLeft={true}
          />

          <TimelineItem
            year="2022"
            title="Guerbet | Cincinnati, OH"
            subtitle="D&E Co-op, Automation Co-op - January 2022 – April 2022"
            description=""
            items={[
              'Researched and developed potential solutions for implementing automated hardware testing efforts.',
              'Developed internal utilities to improve customer service response times and workflows.'
            ]}
            alignLeft={false}
          />

          
          <TimelineItem
            year="2022"
            title="Guerbet | Cincinnati, OH"
            subtitle="D&E Co-op, Software Co-op - August 2022 – December 2022"
            description=""
            items={[
              'Developed expertise in CAN communications for the CiA-425 standard implementation for Injection-Scanner communications.',
              'Provided support to third-party product integrations, increasing global sales capacity.'
            ]}
            alignLeft={true}
          />

          <TimelineItem
            year="2023"
            title="Painless Analytics | Columbus, OH"
            subtitle="Full Stack Software Developer Co-op - May 2023 – August 2023"
            description=""
            items={[
              'Built log rotation scripts for system maintenance, enhancing reliability and performance monitoring.',
              'Led the development of analytics pages to provide detailed insights into user traffic and usage patterns.'
            ]}
            alignLeft={false}
          />

          <TimelineItem
            year="2024"
            title="Painless Analytics | Columbus, OH"
            subtitle="Full Stack Software Developer Co-op - January 2024 – August 2024"
            description="Launched a new subscription-based service, enabling seamless online ordering, integrating Stripe for secure payments, and 
                          automating customer management, leading to increased user engagement and revenue growth."
            items={[
              'Developed reusable React components and styled pages with SCSS, implementing responsive designs based on Figma prototypes.',
              'Created and integrated RESTful API endpoints with CSRF protection for secure data retrieval.',
              'Configured the Orchid Platform for CRUD operations, managing users, roles, and clusters efficiently.'
            ]}
            alignLeft={true}
          />

          <TimelineItem
            year="2025"
            title="University of Cincinnati"
            subtitle="B.S. in Computer Engineering, Minor: Computer Science | Expected 2025"
            description="Member of Theta Tau Professional Engineering Fraternity, TEDx Discovering Leadership Program."
            alignLeft={false}
          />
        </div>
      </section>
    </div>
  );
}

export default AboutPage;
