// src/Components/GraphComponent.jsx
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

// Custom tooltip component
const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { weight, reps, name } = payload[0].payload; // Extract name
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
        <p><strong>Workout:</strong> {name}</p> {/* Display workout name */}
        <p><strong>Weight:</strong> {weight} lbs</p>
        <p><strong>Reps:</strong> {reps}</p>
      </div>
    );
  }
  return null;
};

const GraphComponent = ({ data, xAxisKey = "date", yAxisKey = "weight", showCustomTooltip = false }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis label={{ value: yAxisKey, angle: -90, position: 'insideLeft' }} />
        <Tooltip content={showCustomTooltip ? <CustomTooltip /> : undefined} /> {/* Conditionally use custom tooltip */}
        <Line type="monotone" dataKey={yAxisKey} stroke="#8884d8" name={yAxisKey} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphComponent;
