//src/Pages/NotFoundPage.jsx

import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <div className="container text-center d-flex flex-column align-items-center justify-content-center min-vh-100">
      <h1 className="display-3 text-danger">404</h1>
      <h2 className="mb-4">Page Not Found</h2>
      <p className="lead mb-4">Sorry, the page you are looking for does not exist.</p>
      <Link to="/" className="btn btn-primary">Go Back to Home</Link>
    </div>
  );
}

export default NotFoundPage;
