//src/Components/TimelineItem.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon
import { faClock } from '@fortawesome/free-solid-svg-icons'; // Example icon
import '../scss/timeline.scss';

function TimelineItem({ title, subtitle, description, items, year, alignLeft }) {
  return (
    <div className={`timeline-item-container ${alignLeft ? 'left' : 'right'}`}>
      <div className="timeline-item">
        {/* Add an icon or marker */}
        <div className={`timeline-marker ${alignLeft ? 'align-left' : 'align-right'}`}>
          <FontAwesomeIcon icon={faClock} />
        </div>
        <div className="timeline-content">
          {year && <span className="timeline-year">{year}</span>}
          <h3 className="timeline-title">{title}</h3>
          <p className="timeline-subtitle"><em>{subtitle}</em></p>
          <p>{description}</p>
          {items && (
            <ul>
              {items.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

TimelineItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  year: PropTypes.string,
  alignLeft: PropTypes.bool
};

export default TimelineItem;
